import GoogleMapView from "./google-map-view";
import {Company} from "./entities";
import {Location} from "./location";
import {getLanguage} from "../i18n/localization";

let googleMap: any = null;

let onceAllowed = true,
    elementLoaded = false,
    applicationLoaded = false;

let mapView: GoogleMapView = null,
    waitResult: Array<Company> = [];

let googleMapCenter: Location,
    googleMapZoom: number;

let onCenterChange: (center: Location) => void,
    onZoomChange: (zoom: number) => void;

export function googleMapElementLoaded(loading: boolean) {
    if (loading) {
        elementLoaded = true;

        if (applicationLoaded) {
            once();
        }
    }

    return loading;
}

export function googleMapApplicationLoaded(center: Location, zoom: number, onCenterChangeHandler, onZoomChangeHandler) {
    applicationLoaded = true;

    googleMapCenter = center;
    googleMapZoom = zoom;

    onCenterChange = onCenterChangeHandler;
    onZoomChange = onZoomChangeHandler;

    if (elementLoaded) {
        once();
    }
}

export function googleMapSetCenter(location: Location) {
    googleMapCenter = location;

    if (googleMap !== null) {
        googleMap.setCenter(new google.maps.LatLng(googleMapCenter.latitude, googleMapCenter.longitude));
    }
}

export function googleMapSetZoom(zoom: number) {
    googleMapZoom = zoom;

    if (googleMap !== null) {
        googleMap.setZoom(zoom);
    }
}

export function googleMapRender(result) {
    if (mapView === null) {
        waitResult = result;

        return
    }

    mapView.render(result);
}

function once() {
    if (onceAllowed) {
        load();

        onceAllowed = false;
    }
}

declare const google: any;
declare let window: { gim: any };

function load() {
    window.gim = function () {
        /**
         {
              zoomControl: boolean,
              mapTypeControl: boolean,
              scaleControl: boolean,
              streetViewControl: boolean,
              rotateControl: boolean,
              fullscreenControl: boolean
            }
         */

        const map = new google.maps.Map(document.getElementById("map"), {
            center: {lat: googleMapCenter.latitude, lng: googleMapCenter.longitude},
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            mapTypeControl: false,
            fullscreenControl: false,
            zoom: googleMapZoom,
        });

        map.addListener("center_changed", function () {
            const center = map.getCenter();

            onCenterChange(new Location(center.lat(), center.lng()));
        });

        map.addListener("zoom_changed", function () {
            onZoomChange(map.getZoom());
        });

        mapView = new GoogleMapView(map);

        if (waitResult.length > 0) {
            mapView.render(waitResult);
        }

        googleMap = map;
    };

    const language = getLanguage();

    const hash = String.fromCharCode(65, 73, 122, 97, 83, 121, 67, 55, 55, 108, 72, 77, 121, 116, 66, 113, 116, 48, 89, 81, 98, 106, 111, 87, 89, 100, 48, 69, 70, 107, 110, 122, 113, 48, 51, 78, 83, 75, 73);
    const googleapis = document.createElement("script");
    googleapis.type = "text/javascript";
    googleapis.src = "https://maps.googleapis.com/maps/api/js?key=" + hash + "&language=" + language + "&callback=gim";
    document.body.appendChild(googleapis);
}