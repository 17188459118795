<script>
    import { localization, getLanguage } from "../../i18n/localization";
    import svelte from "svelte";

    const title = localization.title;

    const dispatch = svelte.createEventDispatcher();
    function changeLocation() {
        dispatch("set_current_location");
    }

    function changeLanguage(value) {
        dispatch("set_language", {
            value,
        });
    }

    const language = getLanguage();
</script>

<header class="header">
    <div class="header__r gr">
        <div class="header__c gc">
            <div class="header__logo logo">
                <a href="javascript:void(0);" on:click="{changeLocation}"><img class="logo__img" src="favicon.png" alt="{title}" title="{title}"/></a>
                <span class="logo__p"><a href="/">DevSpace</a></span>
            </div>
            <div class="header__menu">
                <a href="/about">{localization.pages.about}</a>
                <a href="/faq">{localization.pages.faq}</a>
            </div>
            <select class="header__lang" on:change="{(event) => changeLanguage(event.target.value)}" value="{language}">
                <option value="uk">UA</option>
                <option value="en">EN</option>
                <option value="ru">RU</option>
                <option value="am">AM</option>
            </select>
        </div>
    </div>
</header>