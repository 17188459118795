export default {
    "title": "Карта ИТ вакансий",
    "configuration": "Уточнить",
    "search": "Искать",

    "pages": {
        "index": "Главная",
        "about": "Про проект",
        "faq": "FAQ",
        "alternatives": "Альтернативы",
        "discussions": "Обсуждения",
        "roadmap": "Технические планы по проекту или Roadmap",
    },

    "header": {
        "title": "ИТ вакансии на карте Украины",
    },

    "placeholder": {
        "search": "Поиск вакансий",
    },

    "list": "Список",
    "map": "Карта",

    "salary": "Зарплата",
    "city": "Город",
    "company": "Компания",
    "company_size": "Размер компании",
    "company_type": "Тип компании",

    "great": "больше",
    "less": "до",
    "show_all": "показать все",

    "additional": "Дополнительно",
    "company_with_reviews": "с отзывами про компанию",
    "photo_exists": "с фотографиями",
    "company_in_top_largest": "в ТОП 50 наибольших",
    "last_week": "за 7 дней",
    "remote_available": "есть возможность удаленно",

    "humans": "специалистов",
    "example": "Примеру",

    "empty": "Ничего не нашел",

    "cities": {
        "kyiv": "Киев",
        "kharkiv": "Харьков",
        "odessa": "Одесса",
        "lviv": "Львов",
        "rivne": "Ровно",
        "dnipro": "Днепр",
        "zaporizhia": "Запорожье",
        "kremenchuk": "Кременчуг",
        "mykolaiv": "Николаев",
        "poltava": "Полтава",
        "kramatorsk": "Краматорск",
        "mariupol": "Мариуполь",
        "chernihiv": "Чернигов",
        "kherson": "Херсон",
        "uzhhorod": "Ужгород",
        "cherkasy": "Черкассы",
        "vinnica": "Винница",
        "chernivtsi": "Черновцы",
        "ivano-frankivsk": "Ивано-Франковск",
        "khmelnytskyi": "Хмельницкий",
        "zhitomir": "Житомир",
        "lutsk": "Луцк",
        "ternopil": "Тернополь",
        "sumy": "Суммы",
        "kropyvnytskyi": "Кропивницкий",
        "kamianets-podilskyi": "Каменец-Подольский",
        "mukachevo": "Мукачево",
        "boyarka": "Боярка",
        "brovary": "Бровары",
        "kalush": "Калуш",
        "berdyansk": "Бердянск",
        "kamianske": "Каменское",
        "kryvyi-rih": "Кривой Рог",
    },

    "stats": {
        "companies": "компании",
        "vacancies_in_cities": "вакансии\u0020в",
        "cities": "городах\u0020Украины",
        "vacancies_in_city": "вакансии\u0020в\u0020городе",
    },
};