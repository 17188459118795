import {Location} from "./location"
import {getLocationByCityAlias} from "./city-location-map";
import urlStateContainer from "./url-state-container-instance";
import {googleMapSetCenter, googleMapSetZoom} from "./google-map-loader";
import {CITY_ZOOM} from "./settings";

let selectedCityAlias = null;
let currentLocationSelected = false;
const cityAliasUserLocationMap = {};

function storeState() {
    if (selectedCityAlias === null) {
        return;
    }

    const center = urlStateContainer.getCenter();
    if (center === null) {
        return;
    }

    cityAliasUserLocationMap[selectedCityAlias] = center;
}

function getCityLocation(alias: string): Location {
    if (cityAliasUserLocationMap.hasOwnProperty(alias)) {
        return cityAliasUserLocationMap[alias];
    }

    return getLocationByCityAlias(alias);
}

export function changeLocationByCityAlias(alias: string) {
    if (currentLocationSelected) {
        return;
    }

    if (alias === selectedCityAlias) {
        return;
    }

    storeState();

    const cityLocation = getCityLocation(alias);
    if (cityLocation === null) {
        return;
    }

    selectedCityAlias = alias;
    urlStateContainer.setCenter(cityLocation);
    googleMapSetCenter(cityLocation);

    if (urlStateContainer.getZoom() === 0) {
        urlStateContainer.setZoom(CITY_ZOOM);
        googleMapSetZoom(CITY_ZOOM);
    }
}

export function setCurrentLocationSelected() {
    currentLocationSelected = true;
}

