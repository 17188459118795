export default {
    "title": "ՏՏ թափուր աշխատատեղերի քարտեզ",
    "configuration": "Հստակեցնել",
    "search": "Փնտրել",

    "pages": {
        "index": "Հիմնական",
        "about": "Նախագծի մասին",
        "faq": "FAQ",
        "alternatives": "Այլընտրանքներ",
        "discussions": "Քննարկումներ",
        "roadmap": "Ծրագրի ճանապարհային քարտեզ կամ Roadmap",
    },

    "header": {
        "title": "ՏՏ թափուր աշխատատեղեր Ուկրաինայի քարտեզի վրա",
    },

    "placeholder": {
        "search": "Աշխատանքի որոնում",
    },

    "list": "Ցուցակ",
    "map": "Քարտեզ",

    "salary": "Աշխատավարձ",
    "city": "Քաղաք",
    "company": "Ընկերություն",
    "company_size": "Ընկերության չափը",
    "company_type": "Ընկերության տեսակը",

    "great": "ավելին",
    "less": "առաջ",
    "show_all": "ցույց տալ բոլորը",

    "additional": "lրացուցիչ",
    "company_with_reviews": "ընկերության վերաբերյալ կարծիքներով",
    "photo_exists": "լուսանկարներով",
    "company_in_top_largest": "ԹՈՓ 50 ամենամեծ",
    "last_week": "7 օրվա ընթացքում",
    "remote_available": "remote available", // @TODO translate

    "humans": "մասնագետների",
    "example": "Օրինակ",

    "empty": "Ոչինչ չգտնվեց",

    "cities": {
        "kyiv": "Կիև",
        "kharkiv": "Խարկով",
        "odessa": "Օդեսա",
        "lviv": "Լվով",
        "rivne": "Ռովնո",
        "dnipro": "Դնեպր",
        "zaporizhia": "Զապորոժիե",
        "kremenchuk": "Կրեմենչուգ",
        "mykolaiv": "Նիկոլաև",
        "poltava": "Պոլտավա",
        "kramatorsk": "Կրամատորսկ",
        "mariupol": "Մարիուպոլ",
        "chernihiv": "Չեռնիգով",
        "kherson": "Խերսոն",
        "uzhhorod": "Ուժգորոդ",
        "cherkasy": "Չերկասի",
        "vinnica": "Վիննիցա",
        "chernivtsi": "Չեռնովցի",
        "ivano-frankivsk": "Իվանո-Ֆրանկովսկ",
        "khmelnytskyi": "Խմելնիցկի",
        "zhitomir": "Ժիտոմիր",
        "lutsk": "Լուցք",
        "ternopil": "Տերնոպոլ",
        "sumy": "Սումի",
        "kropyvnytskyi": "Կռոպիվնիցկի",
        "kamianets-podilskyi": "Կամենեց-Պոդոլսկ",
        "mukachevo": "Մուկաչևո",
        "boyarka": "Բոյարկա",
        "brovary": "Բրովարի",
        "kalush": "Կալուշ",
        "berdyansk": "Բերդիանսկ",
        "kamianske": "Կամենսկոե",
        "kryvyi-rih": "Կրիվոյ Ռոգ",
    },

    "stats": {
        "companies": "ընկերություններ",
        "vacancies_in_cities": "աշխատատեղեր\u0020",
        "cities": "քաղաքներում\u0020Ուկրաինաի",
        "vacancies_in_city": "աշխատատեղեր\u0020\u0020քաղաքում",
    },
};