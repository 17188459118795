import {Company} from "./entities";

export class Location {
    constructor(public readonly latitude: number,
                public readonly longitude: number) {
    }
}

// will use soon
function sortByNearest(companies: Array<Company>, center: Location) {
    companies.sort(function (a, b) {
        return distance(a.offices[0].location, center) - distance(b.offices[0].location, center);
    });
}

// will use soon
function distance(a: Location, b: Location): number {
    const x = a.latitude - b.latitude;
    const y = a.longitude - b.longitude;

    return x * x + y * y;
}
