export default {
    "title": "Карта ІТ вакансій",
    "configuration": "Уточнити",
    "search": "Шукати",

    "pages": {
        "index": "Головна",
        "about": "Про проект",
        "faq": "FAQ",
        "alternatives": "Альтернативи",
        "discussions": "Обговорення",
        "roadmap": "Технічні плани по проекту або Roadmap",
    },

    "header": {
        "title": "ІТ вакансії на карті України",
    },

    "placeholder": {
        "search": "Пошук вакансій",
    },

    "list": "Список",
    "map": "Карта",

    "salary": "Винагорода",
    "city": "Місто",
    "company": "Компанія",
    "company_size": "Розмір компанії",
    "company_type": "Тип компанії",

    "great": "більше",
    "less": "до",
    "show_all": "показати усі",

    "additional": "Додатково",
    "company_with_reviews": "з відгуками про компанію",
    "photo_exists": "з фотографіями",
    "company_in_top_largest": "в ТОП 50 найбільших",
    "last_week": "за 7 днів",
    "remote_available": "є можливість віддалено",

    "humans": "спеціалістів",
    "example": "Прикладу",

    "empty": "Нічого не знайшов",

    "cities": {
        "kyiv": "Київ",
        "kharkiv": "Харків",
        "odessa": "Одеса",
        "lviv": "Львів",
        "rivne": "Рівне",
        "dnipro": "Дніпро",
        "zaporizhia": "Запоріжжя",
        "kremenchuk": "Кременчук",
        "mykolaiv": "Миколаїв",
        "poltava": "Полтава",
        "kramatorsk": "Краматорськ",
        "mariupol": "Маріуполь",
        "chernihiv": "Чернігів",
        "kherson": "Херсон",
        "uzhhorod": "Ужгород",
        "cherkasy": "Черкаси",
        "vinnica": "Вінниця",
        "chernivtsi": "Чернівці",
        "ivano-frankivsk": "Івано-Франківськ",
        "khmelnytskyi": "Хмельницький",
        "zhitomir": "Житомир",
        "lutsk": "Луцьк",
        "ternopil": "Тернопіль",
        "sumy": "Суми",
        "kropyvnytskyi": "Кропивницький",
        "kamianets-podilskyi": "Кам’янець-Подільський",
        "mukachevo": "Мукачево",
        "boyarka": "Боярка",
        "brovary": "Бровари",
        "kalush": "Калуш",
        "berdyansk": "Бердянськ",
        "kamianske": "Кам'янське",
        "kryvyi-rih": "Кривий Ріг",
    },

    "stats": {
        "companies": "компанії",
        "vacancies_in_cities": "вакансії\u0020в",
        "cities": "містах\u0020України",
        "vacancies_in_city": "вакансії\u0020в\u0020місті",
    },
};