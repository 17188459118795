<script>
    import { localization } from "../../i18n/localization";
</script>

<footer class="footer">
    <div class="gr">
        <div class="footer__menu gc">
            <a href="/">{localization.pages.index}</a>
            <a href="/about">{localization.pages.about}</a>
            <a href="/faq">{localization.pages.faq}</a>
            <a href="/alternatives">{localization.pages.alternatives}</a>
            <a href="/discussions">{localization.pages.discussions}</a>
            <a href="/roadmap">{localization.pages.roadmap}</a>
        </div>
    </div>
    <div class="gr">
        <div class="gc">
            <span class="footer__copy">&copy;DevSpace 2020</span>
        </div>
    </div>
</footer>