import {Location} from "./location";

export const COMPANY_HINT_LIMIT = 25;
export const CITY_HINT_LIMIT = 25;
export const MAX_HINT_LIMIT = Math.max(COMPANY_HINT_LIMIT, CITY_HINT_LIMIT);
export const POPULAR_CITIES_LIMIT = 5;
export const POPULAR_COMMPANIES_LIMIT = 5;
export const RESULT_COUNT = 100;
export const DEFAULT_ZOOM = 6;
export const CITY_ZOOM = 12;
export const DEFAULT_UKRAINE_CENTER = new Location(49.5106, 31.6016);
export const DEFAULT_LANGUAGE = "uk";