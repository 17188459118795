<script>
    import { localization } from "../../i18n/localization";

    export let resultStats;
    export let selectedCityName;
</script>

<div class="result__stats">
    <div class="stats__head">
        <div class="stats__h">
            {#if selectedCityName === ""}
                <span class="stats__h-val">{resultStats.companyCount}</span>&nbsp;{localization.stats.companies}, <span class="stats__h-val">{resultStats.vacancyCount}</span>&nbsp;{localization.stats.vacancies_in_cities}&nbsp;<span class="stats__h-val">{resultStats.cityCount}</span>&nbsp;{localization.stats.cities}
            {:else}
                <span class="stats__h-val">{resultStats.companyCount}</span>&nbsp;{localization.stats.companies}, <span class="stats__h-val">{resultStats.vacancyCount}</span>&nbsp;{localization.stats.vacancies_in_city}&nbsp;{selectedCityName}
            {/if}
        </div>
    </div>
</div>