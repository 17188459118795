import {Location} from "./location";

export class Company {
    constructor(
        public readonly alias: string,
        public readonly name: string,
        public readonly offices: Array<Office>,
        public readonly vacancies: Array<Vacancy>,
        public readonly review_count: number,
        public readonly employee_count: string,
        public readonly type: string,
        public readonly top50Largest: boolean,
        public readonly photoExists: boolean,
    ) {
    }
}

export class City {
    constructor(
        public readonly alias: string,
        public readonly name: string,
    ) {

    }
}

export class Office {
    constructor(
        public readonly city: City,
        public readonly address: string,
        public readonly location: Location,
    ) {
    }
}

export class Vacancy {
    constructor(
        public readonly id: number,
        public readonly title: string,
        public readonly titleIndex: number,
        public readonly cities: Array<City>,
        public readonly existsOffice: boolean,
        public readonly salary: SalaryTitleRange,
        public readonly published: string,
        public readonly remoteAvailable: boolean,
    ) {
    }
}

export class SalaryTitleRange {
    constructor(
        public readonly title: string,
        public readonly from: number,
        public readonly to: number,
    ) {

    }
}

export function salaryVacancyCount(vacancies: Array<Vacancy>): number {
    let result = 0;

    for (let i = 0; i < vacancies.length; i++) {
        const vacancy = vacancies[i];

        if (vacancy.salary !== null) {
            result += 1;
        }
    }

    return result;
}