import {SalaryTitleRange} from "./entities";

const MIN_SALARY = 500;
const SALARY_STEP = 500;

export class SalaryTitleRangeService {
    private readonly map: { [s: string]: SalaryTitleRange };

    constructor() {
        this.map = {};
    }

    solve(title): SalaryTitleRange {
        if (title === "") {
            return null;
        }

        if (this.map.hasOwnProperty(title)) {
            return this.map[title];
        }

        const result = parse(title);

        if (result === null) {
            console.error("fail parse salary", title);
        }

        this.map[title] = result;

        return result;
    }
}

function parse(title: string): SalaryTitleRange {
    if (title[0] === "$") {
        const delimiter = title.indexOf("–");

        if (delimiter === -1) {
            const one = parseInt(title.substr(1), 10);
            if (isNaN(one)) {
                return null;
            }

            return new SalaryTitleRange(title, one, one);
        }

        const from = parseInt(title.substr(1, delimiter), 10);
        if (isNaN(from)) {
            return null;
        }

        const to = parseInt(title.substr(delimiter + 1), 10);
        if (isNaN(to)) {
            return null;
        }

        return new SalaryTitleRange(title, from, to);
    }

    const toPrefix = "до $";
    if (title.indexOf(toPrefix) === 0) {
        const to = parseInt(title.substr(toPrefix.length), 10);
        if (isNaN(to)) {
            return null;
        }

        return new SalaryTitleRange(title, findSalaryFromByTo(to), to);
    }

    const fromPrefixes = ["от $", "від $"];
    for (const fromPrefix of fromPrefixes) {
        if (title.indexOf(fromPrefix) === 0) {
            const from = parseInt(title.substr(fromPrefix.length), 10);
            if (isNaN(from)) {
                return null;
            }

            return new SalaryTitleRange(title, from, findSalaryToByFrom(from));
        }
    }

    return null;
}

function findSalaryFromByTo(to: number): number {
    return Math.max(MIN_SALARY, to - SALARY_STEP, to * 0.8)
}

function findSalaryToByFrom(from: number): number {
    return Math.max(from + SALARY_STEP, from * 1.2);
}