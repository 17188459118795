export default {
    "title": "IT jobs map",
    "configuration": "Settings",
    "search": "Search",

    "pages": {
        "index": "Main",
        "about": "About",
        "faq": "FAQ",
        "alternatives": "Alternatives",
        "discussions": "Discussions",
        "roadmap": "Roadmap",
    },

    "header": {
        "title": "Ukrainian IT jobs map",
    },

    "placeholder": {
        "search": "Search jobs",
    },

    "list": "List",
    "map": "Map",

    "salary": "Salary",
    "city": "City",
    "company": "Company",
    "company_size": "Company size",
    "company_type": "Company type",

    "great": "great",
    "less": "less",
    "show_all": "show all",

    "additional": "Additional",
    "company_with_reviews": "with reviews",
    "photo_exists": "with photos",
    "company_in_top_largest": "in TOP 50 largest",
    "last_week": "by last week",
    "remote_available": "remote available",

    "humans": "humans",
    "example": "Example",

    "empty": "Nothing to show",

    "cities": {
        "kyiv": "Kyiv",
        "kharkiv": "Kharkiv",
        "odessa": "Odessa",
        "lviv": "Lviv",
        "rivne": "Rivne",
        "dnipro": "Dnipro",
        "zaporizhia": "Zaporizhia",
        "kremenchuk": "Kremenchuk",
        "mykolaiv": "Mykolaiv",
        "poltava": "Poltava",
        "kramatorsk": "Kramatorsk",
        "mariupol": "Mariupol",
        "chernihiv": "Chernihiv",
        "kherson": "Kherson",
        "uzhhorod": "Uzhhorod",
        "cherkasy": "Cherkasy",
        "vinnica": "Vinnytsia",
        "chernivtsi": "Chernivtsi",
        "ivano-frankivsk": "Ivano-Frankivsk",
        "khmelnytskyi": "Khmelnytskyi",
        "zhitomir": "Zhytomyr",
        "lutsk": "Lutsk",
        "ternopil": "Ternopil",
        "sumy": "Sumy",
        "kropyvnytskyi": "Kropyvnytskyi",
        "kamianets-podilskyi": "Kamianets-Podilskyi",
        "mukachevo": "Mukachevo",
        "boyarka": "Boyarka",
        "brovary": "Brovary",
        "kalush": "Kalush",
        "berdyansk": "Berdyansk",
        "kamianske": "Kamianske",
        "kryvyi-rih": "Kryvyi Rih",
    },

    "stats": {
        "companies": "companies",
        "vacancies_in_cities": "jobs\u0020in",
        "cities": " cities\u0020of\u0020Ukraine",
        "vacancies_in_city": "jobs\u0020in\u0020city",
    },
};