import {ModifierBuilder, Modifier} from "./modifier";
import VacancyModifier from "./vacancy-modifier";

export default class RemoteAvailableModifierBuilder implements ModifierBuilder {
    constructor(
        private readonly state: () => boolean,
    ) {
    }

    build(): Modifier {
        const range = this.state();

        if (range === true) {
            return remoteAvailableModifier;
        }

        return null;
    }
}

const remoteAvailableModifier = new class extends VacancyModifier {
    constructor() {
        super(function (vacancy) {
            return vacancy.remoteAvailable;
        });
    }
};
