import filterByExists from "./filter-by-exists";

export const PRODUCT = "product";
export const STARTUP = "startup";
export const OUTSOURCE = "outsource";
export const RECRUITMENT_AGENCY = "recruitment-agency";
export const ACADEMY = "academy";

const COMPANY_TYPES = [
    PRODUCT,
    STARTUP,
    OUTSOURCE,
    RECRUITMENT_AGENCY,
    ACADEMY,
];

const companyAliasNameMap = {
    [PRODUCT]: "Product",
    [STARTUP]: "Startup",
    [OUTSOURCE]: "Outsource",
    [RECRUITMENT_AGENCY]: "Recruitment agency",
    [ACADEMY]: "Academy",
};

export function companyTypesFilterByExists(aliases: Array<string>): Array<string> {
    return filterByExists(COMPANY_TYPES, aliases);
}

export function getCompanyTypeNameByAlias(alias: string): string {
    if (companyAliasNameMap.hasOwnProperty(alias)) {
        return companyAliasNameMap[alias];
    }

    return "";
}