import {createAliasMap} from "./alias-map";

export default function filterByExists(existsAliases: Array<string>, selectedAliases: Array<string>): Array<string> {
    if (selectedAliases.length === 0) {
        return selectedAliases;
    }

    const aliasMap = createAliasMap(selectedAliases, true);

    const result = [];

    for (let i = 0; i < existsAliases.length; i++) {
        const existsAlias = existsAliases[i];

        if (aliasMap.hasOwnProperty(existsAlias)) {
            result.push(existsAlias);
        }
    }

    return result;
}