import filterByExists from "./filter-by-exists";

export const CS_1500 = "1500+";
export const CS_800 = "800—1500";
export const CS_200 = "200—800";
export const CS_81 = "81—200";
export const CS_1 = "1—80";

const COMPANY_SIZES = [
    CS_1500,
    CS_800,
    CS_200,
    CS_81,
    CS_1,
];

export function companySizesFilterByExists(aliases: Array<string>): Array<string> {
    return filterByExists(COMPANY_SIZES, aliases);
}