<script>
    import svelte from "svelte";

    import { localization } from "../../i18n/localization";
    import {EXAMPLES} from "../../core/examples";

    const dispatch = svelte.createEventDispatcher();

    function submit(search) {
        dispatch("example_sumbit", {
            search,
        });
    }
</script>

<div class="search__help help">
    <span class="help__h">{localization.example}:</span>
    {#each EXAMPLES as example}
        <div class="help__a" on:click="{() => submit(example)}"><span>{example}</span></div>
    {/each}
</div>