export const EXAMPLES = [
    "Designer",
    "QA",
    "Front end",
    "Python",
    "C++",
    ".NET",
    "Scala",
    "Go",
    "Java",
    "DevOps",
    "Data Science",
    "PHP",
    "Ruby",
];