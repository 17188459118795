import App from "./components/app.svelte";
import {POPUP_MAP, POPUP_START} from "./core/popup";
import {googleMapApplicationLoaded, googleMapRender, googleMapSetCenter} from "./core/google-map-loader";
import dataProvider from "./core/fetch";
import unmarshal from "./core/zip";
import {Location} from "./core/location";

import {setUserAgentClassNames} from "./browser/navigator";
import urlStateContainer from "./core/url-state-container-instance";
import {
    CITY_CRITERIA_NAME,
    COMPANY_CRITERIA_NAME,
    COMPANY_PHOTO_EXISTS_CRITERIA_NAME,
    COMPANY_SIZE_CRITERIA_NAME,
    COMPANY_TYPE_CRITERIA_NAME,
    NEWEST_CRITERIA_NAME, REMOTE_AVAILABLE_CRITERIA_NAME,
    REVIEW_COUNT_CRITERIA_NAME,
    SALARY_CRITERIA_NAME,
    SEARCH_QUERY_VACANCY,
    TOP_50_LARGEST_CRITERIA_NAME
} from "./core/names";
import FilterContainer from "./core/filtration/container";
import SalaryModifierBuilder from "./core/filtration/salary-modifier";
import ReviewsMatcherBuilder from "./core/filtration/reviews-matcher";
import TopLargestMatcherBuilder from "./core/filtration/top-largest-matcher";
import NewestModifierBuilder from "./core/filtration/newest-modifier";
import TitleModifierBuilder from "./core/filtration/title-modifer";
import {findCityByAlias, popularCities} from "./core/cities";
import {fillCompaniesProvider, findCompanyByAlias} from "./core/companies";
import CompanyMatcherBuilder from "./core/filtration/company-matcher";
import {City, Company} from "./core/entities";
import CityModifierBuilder from "./core/filtration/city-modifer";
import CompanyPhotoExistsMatcherBuilder from "./core/filtration/company-photo-exists-matcher";
import {companySizesFilterByExists} from "./core/company-sizes";
import {companyTypesFilterByExists} from "./core/company-types";
import CompanySizesMatcherBuilder from "./core/filtration/company-size-matcher";
import {
    DEFAULT_LANGUAGE,
    DEFAULT_UKRAINE_CENTER,
    DEFAULT_ZOOM,
    POPULAR_CITIES_LIMIT,
    POPULAR_COMMPANIES_LIMIT,
    RESULT_COUNT
} from "./core/settings";
import {changeLocationByCityAlias, setCurrentLocationSelected} from "./core/location_by_city";
import {solveResultStats} from "./core/result-stats";
import CompanyTypesMatcherBuilder from "./core/filtration/company-type-matcher";
import {setLanguage} from "./i18n/localization";
import RemoteAvailableModifierBuilder from "./core/filtration/remote-available-modifier";

const body = document.body;

let companies = [];

function getSalaryCriteria() {
    return urlStateContainer.getCriteriaByName(SALARY_CRITERIA_NAME, null);
}

function getCompanySizesCriteria(): Array<string> {
    const aliases = urlStateContainer.getCriteriaByName(COMPANY_SIZE_CRITERIA_NAME, []);

    return companySizesFilterByExists(aliases);
}

function getCompanyTypesCriteria(): Array<string> {
    const aliases = urlStateContainer.getCriteriaByName(COMPANY_TYPE_CRITERIA_NAME, []);

    return companyTypesFilterByExists(aliases);
}

function getSearchCriteria() {
    return urlStateContainer.getCriteriaByName(SEARCH_QUERY_VACANCY, "");
}

function getPopupStart() {
    return urlStateContainer.getTab();
}

function getCompanyCriteria(): Company {
    const alias = urlStateContainer.getCriteriaByName(COMPANY_CRITERIA_NAME, "");

    return findCompanyByAlias(alias);
}

function getCityCriteria(): City {
    const alias = urlStateContainer.getCriteriaByName(CITY_CRITERIA_NAME, "");

    return findCityByAlias(alias);
}

function fetchCriteriaName(criteria: any): string {
    if (criteria === null) {
        return "";
    }

    // City or Company
    return criteria.name;
}

function getCheckedCriteria(name: string): boolean {
    return urlStateContainer.getCriteriaByName(name, false);
}

function getReviewsCriteria() {
    return getCheckedCriteria(REVIEW_COUNT_CRITERIA_NAME);
}

function getTopLargestCriteria() {
    return getCheckedCriteria(TOP_50_LARGEST_CRITERIA_NAME);
}

function getPhotoExistsCriteria() {
    return getCheckedCriteria(COMPANY_PHOTO_EXISTS_CRITERIA_NAME);
}

function getNewestCriteria() {
    return getCheckedCriteria(NEWEST_CRITERIA_NAME);
}

function getRemoteAvailableCriteria() {
    return getCheckedCriteria(REMOTE_AVAILABLE_CRITERIA_NAME);
}

function setCheckedCriteriaCallback(name: string) {
    return function (event) {
        urlStateContainer.setCheckedCriteria(name, event.detail.checked);
    };
}

function setUncheckedCriteriaCallback(name: string) {
    return function (event) {
        urlStateContainer.setCheckedCriteria(name, false);

        search();
    };
}

setUserAgentClassNames(body);
setLanguage(window.location.pathname.substr(1, 3));

const app = new App({
    target: body,
    props: {
        popupOpenState: getPopupStart(),

        search: getSearchCriteria(),
        selectedCityName: "", // will set on data loaded
        selectedCompanyName: "", // will set on data loaded
        salaryFilterValue: getSalaryCriteria(),
        companySizesFilterValue: getCompanySizesCriteria(),
        companyTypesFilterValue: getCompanyTypesCriteria(),
        reviewsFilterValue: getReviewsCriteria(),
        topLargestFilterValue: getTopLargestCriteria(),
        newestFilterValue: getNewestCriteria(),
        remoteAvailableFilterValue: getRemoteAvailableCriteria(),
        photoExistsFilterValue: getPhotoExistsCriteria(),

        result: [],
        resultStats: null,
        fastCities: [],
        fastCompanies: [],
    }
});

function search() {
    urlStateContainer.storeCurrentState();

    const result = filterContainer.filter(companies);

    googleMapRender(result);

    const selectedCity = getCityCriteria();
    if (selectedCity !== null) {
        changeLocationByCityAlias(selectedCity.alias);
    }

    app.$set({
        search: getSearchCriteria(),
        selectedCityName: fetchCriteriaName(selectedCity),
        selectedCompanyName: fetchCriteriaName(getCompanyCriteria()),
        salaryFilterValue: getSalaryCriteria(),
        companySizesFilterValue: getCompanySizesCriteria(),
        companyTypesFilterValue: getCompanyTypesCriteria(),
        reviewsFilterValue: getReviewsCriteria(),
        topLargestFilterValue: getTopLargestCriteria(),
        newestFilterValue: getNewestCriteria(),
        remoteAvailableFilterValue: getRemoteAvailableCriteria(),
        photoExistsFilterValue: getPhotoExistsCriteria(),
        result: result.slice(0, RESULT_COUNT),
        resultStats: solveResultStats(result, selectedCity === null),
    });
}

app.$on("city_changed", function (event) {
    const value = event.detail.value;

    urlStateContainer.setAliasCriteria(CITY_CRITERIA_NAME, value);
});

app.$on("city_removed", function (event) {
    urlStateContainer.setAliasCriteria(CITY_CRITERIA_NAME, null);

    search();
});

app.$on("company_changed", function (event) {
    const value = event.detail.value;

    urlStateContainer.setAliasCriteria(COMPANY_CRITERIA_NAME, value);
});

app.$on("company_removed", function (event) {
    urlStateContainer.setAliasCriteria(COMPANY_CRITERIA_NAME, null);

    search();
});

app.$on("salary_changed", function (event) {
    const value = event.detail.value;

    urlStateContainer.setOrDeleteCriteria(value !== null, SALARY_CRITERIA_NAME, value);
});

app.$on("salary_removed", function (event) {
    urlStateContainer.setOrDeleteCriteria(false, SALARY_CRITERIA_NAME, null);

    search();
});

app.$on("company_sizes_changed", function (event) {
    const aliases = event.detail.aliases;

    urlStateContainer.setOrDeleteCriteria(aliases.length > 0, COMPANY_SIZE_CRITERIA_NAME, aliases);
});

app.$on("company_sizes_removed", function (event) {
    const alias = event.detail.alias;

    const aliases = getCompanySizesCriteria().filter(function (value) {
        return value !== alias;
    });

    urlStateContainer.setOrDeleteCriteria(aliases.length > 0, COMPANY_SIZE_CRITERIA_NAME, aliases);

    search();
});

app.$on("company_types_changed", function (event) {
    const aliases = event.detail.aliases;

    urlStateContainer.setOrDeleteCriteria(aliases.length > 0, COMPANY_TYPE_CRITERIA_NAME, aliases);
});

app.$on("company_types_removed", function (event) {
    const alias = event.detail.alias;

    const aliases = getCompanyTypesCriteria().filter(function (value) {
        return value !== alias;
    });

    urlStateContainer.setOrDeleteCriteria(aliases.length > 0, COMPANY_TYPE_CRITERIA_NAME, aliases);

    search();
});

app.$on("set_current_location", function (event) {
    if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(function (position) {
            const center = new Location(position.coords.latitude, position.coords.longitude);

            urlStateContainer.setCenter(center);
            googleMapSetCenter(center);
            setCurrentLocationSelected();

            app.$set({
                popupOpenState: POPUP_MAP,
            });
        });
    }
});

app.$on("set_language", function (event) {
    let language = event.detail.value;
    if (language === DEFAULT_LANGUAGE) {
        language = "";
    }

    window.location.href = "/" + language + urlStateContainer.getQueryParams();
});

app.$on("reviews_changed", setCheckedCriteriaCallback(REVIEW_COUNT_CRITERIA_NAME));
app.$on("reviews_removed", setUncheckedCriteriaCallback(REVIEW_COUNT_CRITERIA_NAME));

app.$on("in_top_largest_changed", setCheckedCriteriaCallback(TOP_50_LARGEST_CRITERIA_NAME));
app.$on("in_top_largest_removed", setUncheckedCriteriaCallback(TOP_50_LARGEST_CRITERIA_NAME));

app.$on("newest_changed", setCheckedCriteriaCallback(NEWEST_CRITERIA_NAME));
app.$on("newest_removed", setUncheckedCriteriaCallback(NEWEST_CRITERIA_NAME));

app.$on("remote_available_changed", setCheckedCriteriaCallback(REMOTE_AVAILABLE_CRITERIA_NAME));
app.$on("remote_available_removed", setUncheckedCriteriaCallback(REMOTE_AVAILABLE_CRITERIA_NAME));

app.$on("photo_exists_changed", setCheckedCriteriaCallback(COMPANY_PHOTO_EXISTS_CRITERIA_NAME));
app.$on("photo_exists_removed", setUncheckedCriteriaCallback(COMPANY_PHOTO_EXISTS_CRITERIA_NAME));

app.$on("search_sumbit", function (event) {
    const value = event.detail.search.trim();

    urlStateContainer.setOrDeleteCriteria(value !== "", SEARCH_QUERY_VACANCY, value);

    search();
});

app.$on("example_sumbit", function (event) {
    const value = event.detail.search;

    urlStateContainer.setOrDeleteCriteria(value !== "", SEARCH_QUERY_VACANCY, value);

    search();
});

const filterContainer = new FilterContainer(
    {
        // company matcher must be first
        [COMPANY_CRITERIA_NAME]: new CompanyMatcherBuilder(getCompanyCriteria),
        [REVIEW_COUNT_CRITERIA_NAME]: new ReviewsMatcherBuilder(getReviewsCriteria),
        [TOP_50_LARGEST_CRITERIA_NAME]: new TopLargestMatcherBuilder(getTopLargestCriteria),
        [COMPANY_PHOTO_EXISTS_CRITERIA_NAME]: new CompanyPhotoExistsMatcherBuilder(getPhotoExistsCriteria),
        [COMPANY_SIZE_CRITERIA_NAME]: new CompanySizesMatcherBuilder(getCompanySizesCriteria),
        [COMPANY_TYPE_CRITERIA_NAME]: new CompanyTypesMatcherBuilder(getCompanyTypesCriteria),
    },
    {
        [SEARCH_QUERY_VACANCY]: new TitleModifierBuilder(getSearchCriteria),
        [NEWEST_CRITERIA_NAME]: new NewestModifierBuilder(getNewestCriteria),
        [REMOTE_AVAILABLE_CRITERIA_NAME]: new RemoteAvailableModifierBuilder(getRemoteAvailableCriteria),
        [SALARY_CRITERIA_NAME]: new SalaryModifierBuilder(getSalaryCriteria),
        [CITY_CRITERIA_NAME]: new CityModifierBuilder(getCityCriteria),
    },
);

function initOnce(source: Array<any>) {
    companies = unmarshal(source);

    fillCompaniesProvider(companies);

    const result = filterContainer.filter(companies);

    googleMapRender(result);

    const selectedCity = getCityCriteria();
    if (selectedCity !== null) {
        changeLocationByCityAlias(selectedCity.alias);
    }

    app.$set({
        result: result.slice(0, RESULT_COUNT),
        resultStats: solveResultStats(result, selectedCity === null),
        selectedCityName: fetchCriteriaName(selectedCity),
        selectedCompanyName: fetchCriteriaName(getCompanyCriteria()),
        fastCities: popularCities().slice(0, POPULAR_CITIES_LIMIT),
        fastCompanies: companies.slice(0, POPULAR_COMMPANIES_LIMIT),
    });
}

app.$on("search", search);

let zoom = urlStateContainer.getZoom();
if (zoom === 0) {
    zoom = DEFAULT_ZOOM;
}

let center = urlStateContainer.getCenter();
if (center === null) {
    center = DEFAULT_UKRAINE_CENTER;
} else {
    setCurrentLocationSelected();
}

googleMapApplicationLoaded(
    center,
    zoom,
    urlStateContainer.setCenter.bind(urlStateContainer),
    urlStateContainer.setZoom.bind(urlStateContainer),
);

dataProvider(initOnce, console.error);

export default app;