import {Matcher, MatcherBuilder} from "./matcher";
import {Company} from "../entities";
import {createAliasMap} from "../alias-map";

class CompanyTypesMatcher implements Matcher {
    constructor(
        private readonly aliasMap: { [s: string]: boolean },
    ) {
    }

    match(item: Company) {
        const companyType = item.type;

        return this.aliasMap.hasOwnProperty(companyType) && this.aliasMap[companyType] === true;
    }
}

export default class CompanyTypesMatcherBuilder implements MatcherBuilder {
    constructor(
        private readonly state: () => Array<string>,
    ) {
    }

    build(): Matcher {
        const aliases = this.state();

        if (aliases.length === 0) {
            return null;
        }

        return new CompanyTypesMatcher(createAliasMap(aliases, true));
    }
}