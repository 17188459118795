import UniqueCounter from "./unique-counter";
import {Company} from "./entities";

class ResultStats {
    constructor(
        public readonly companyCount: number,
        public readonly vacancyCount: number,
        public readonly cityCount: number,
    ) {
    }
}

export function solveResultStats(companies: Array<Company>, withCities: boolean): ResultStats {
    let vacancyCount = 0;
    const cityCounter = new UniqueCounter();

    for (let i = 0; i < companies.length; i++) {
        const vacancies = companies[i].vacancies;

        vacancyCount += vacancies.length;

        if (withCities) {
            for (let j = 0; j < vacancies.length; j++) {
                const vacancy = vacancies[j];
                const cities = vacancy.cities;

                for (let k = 0; k < cities.length; k++) {
                    const city = cities[k];

                    if (city.alias !== "") {
                        cityCounter.add(city.alias);
                    }
                }
            }
        }
    }

    return new ResultStats(
        companies.length,
        vacancyCount,
        cityCounter.count(),
    );
}