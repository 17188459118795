import {SalaryTitleRange} from "./entities";

export function vacancyUrl(companyAlias, vacancyId) {
    return `https://jobs.dou.ua/companies/${companyAlias}/vacancies/${vacancyId}/`;
}

export function companyUrl(alias) {
    return `https://devspace.com.ua/companies/${alias}/`;
}

export function salary(value: SalaryTitleRange) {
    if (value === null) {
        return "";
    }

    return " (" + value.title + ")"
}