<script>
    import { localization } from "../../i18n/localization";
    import {createAliasMap, filterAliasMap} from "../../core/alias-map"
    import {PRODUCT, STARTUP, OUTSOURCE, RECRUITMENT_AGENCY, ACADEMY} from "../../core/company-types"
    import svelte from "svelte";

    const dispatch = svelte.createEventDispatcher();

    export let types;

    function update(aliases) {
        dispatch("company_types_changed", {
            aliases,
        });
    }

    function change(alias, checked) {
        const typeCheckedMap = createAliasMap(types, true);

        typeCheckedMap[alias] = checked;

        if (typeCheckedMap[alias] === true) {
            types = [...types, alias];
        } else {
            types = types.filter(size => size !== alias);
        }

        update(filterAliasMap(typeCheckedMap));
    }
</script>

<div class="filters__it filter">
    <div class="filter__head">
        <p class="filter__h">{localization.company_type}</p>
    </div>
    <div class="filter__body">
        <div class="filter__it">
            <label>
                <input
                    type="checkbox"
                    checked="{types.indexOf(PRODUCT) !== -1}"
                    on:change="{e => change(PRODUCT, e.target.checked)}"
                />
                <span>Product</span>
            </label>
        </div>
        <div class="filter__it">
            <label>
                <input
                    type="checkbox"
                    checked="{types.indexOf(STARTUP) !== -1}"
                    on:change="{e => change(STARTUP, e.target.checked)}"
                />
                <span>Startup</span>
            </label>
        </div>
        <div class="filter__it">
            <label>
                <input
                    type="checkbox"
                    checked="{types.indexOf(OUTSOURCE) !== -1}"
                    on:change="{e => change(OUTSOURCE, e.target.checked)}"
                />
                <span>Outsource</span>
            </label>
        </div>
        <div class="filter__it">
            <label>
                <input
                    type="checkbox"
                    checked="{types.indexOf(RECRUITMENT_AGENCY) !== -1}"
                    on:change="{e => change(RECRUITMENT_AGENCY, e.target.checked)}"
                />
                <span>Recruitment agency</span>
            </label>
        </div>
        <div class="filter__it">
            <label>
                <input
                    type="checkbox"
                    checked="{types.indexOf(ACADEMY) !== -1}"
                    on:change="{e => change(ACADEMY, e.target.checked)}"
                />
                <span>Academy</span>
            </label>
        </div>
    </div>
</div>