<script>
    import MapPopup from "./popup/map.svelte"
    import ConfigPopup from "./popup/config.svelte"
    import Header from "./main/header.svelte"
    import Main from "./main/main.svelte"
    import Footer from "./main/footer.svelte"

    import {POPUP_MAP, POPUP_CONFIG, POPUP_NONE} from "../core/popup";
    import {googleMapElementLoaded} from "../core/google-map-loader";
    import urlStateContainer from "../core/url-state-container-instance";

    export let popupOpenState;
    export let result;
    export let resultStats;

    // filters
    export let search;
    export let salaryFilterValue;
    export let companySizesFilterValue;
    export let companyTypesFilterValue;
    export let reviewsFilterValue;
    export let topLargestFilterValue;
    export let newestFilterValue;
    export let remoteAvailableFilterValue;
    export let photoExistsFilterValue;

    // filter pretty view
    export let selectedCityName;
    export let selectedCompanyName;

    // fast
    export let fastCities;
    export let fastCompanies;

    let beforePopupOpenState;

    function setConfigPopupOpen() {
        beforePopupOpenState = popupOpenState;

        popupOpenState = POPUP_CONFIG;
    }

    function setMapPopupOpen() {
        urlStateContainer.setTab(POPUP_MAP);

        popupOpenState = POPUP_MAP;
    }

    function setBeforeStatePopupOpen() {
        popupOpenState = beforePopupOpenState;
    }

    function setNonePopupOpen() {
        urlStateContainer.setTab(POPUP_NONE);

        popupOpenState = POPUP_NONE;
    }

    $: popupOpenStateMap = googleMapElementLoaded(popupOpenState === POPUP_MAP)
</script>

<div id="root">
    <Header
        on:set_current_location
        on:set_language
    />
    <Main
        bind:search
        on:search_sumbit
        on:example_sumbit

        on:salary_removed
        on:city_removed
        on:company_removed
        on:company_sizes_removed
        on:company_types_removed
        on:reviews_removed
        on:in_top_largest_removed
        on:newest_removed
        on:remote_available_removed
        on:photo_exists_removed

        salaryFilterValue="{salaryFilterValue}"
        companySizesFilterValue="{companySizesFilterValue}"
        companyTypesFilterValue="{companyTypesFilterValue}"
        reviewsFilterValue="{reviewsFilterValue}"
        topLargestFilterValue="{topLargestFilterValue}"
        newestFilterValue="{newestFilterValue}"
        remoteAvailableFilterValue="{remoteAvailableFilterValue}"
        photoExistsFilterValue="{photoExistsFilterValue}"

        selectedCityName="{selectedCityName}"
        selectedCompanyName="{selectedCompanyName}"

        on:show_map="{setMapPopupOpen}"
        on:show_configuration="{setConfigPopupOpen}"
        result="{result}"
        resultStats="{resultStats}"
    />
    <Footer/>
    <MapPopup
        bind:search
        on:search_sumbit

        open="{popupOpenStateMap}"
        on:show_configuration="{setConfigPopupOpen}"
        on:close="{setNonePopupOpen}"
    />
    <ConfigPopup
        open="{popupOpenState === POPUP_CONFIG}"
        on:close="{setBeforeStatePopupOpen}"

        salaryFilterValue="{salaryFilterValue}"
        companySizesFilterValue="{companySizesFilterValue}"
        companyTypesFilterValue="{companyTypesFilterValue}"
        reviewsFilterValue="{reviewsFilterValue}"
        topLargestFilterValue="{topLargestFilterValue}"
        newestFilterValue="{newestFilterValue}"
        remoteAvailableFilterValue="{remoteAvailableFilterValue}"
        photoExistsFilterValue="{photoExistsFilterValue}"

        selectedCityName="{selectedCityName}"
        selectedCompanyName="{selectedCompanyName}"

        fastCities="{fastCities}"
        fastCompanies="{fastCompanies}"

        on:search
        on:city_changed
        on:company_changed
        on:salary_changed
        on:company_sizes_changed
        on:company_types_changed
        on:reviews_changed
        on:in_top_largest_changed
        on:newest_changed
        on:remote_available_changed
        on:photo_exists_changed
    />
</div>