export default class UniqueCounter {
    private readonly map: { [s: string]: boolean };
    private _count: number;

    constructor() {
        this.map = {};
        this._count = 0;
    }

    add(value) {
        if (this.map.hasOwnProperty(value)) {
            return;
        }

        this.map[value] = true;

        ++this._count;
    }

    count() {
        return this._count;
    }
}