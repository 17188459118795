<script>
    import { localization } from "../../i18n/localization";
    import { getCompanyTypeNameByAlias } from "../../core/company-types"
    import svelte from "svelte";

    import {
        R_5000_TO_MORE,
        R_3000_TO_5000,
        R_2000_TO_3000,
        R_1000_TO_2000,
        R_500_TO_1000,
    } from "../../core/default_price_ranges";
    import {CS_1500, CS_800, CS_200, CS_81, CS_1} from "../../core/company-sizes"

    // filters
    export let salaryFilterValue;
    export let companySizesFilterValue;
    export let companyTypesFilterValue;
    export let reviewsFilterValue;
    export let topLargestFilterValue;
    export let newestFilterValue;
    export let remoteAvailableFilterValue;
    export let photoExistsFilterValue;

    // filter pretty view
    export let selectedCityName;
    export let selectedCompanyName;

    const dispatch = svelte.createEventDispatcher();

    function salaryRemove() {
        dispatch("salary_removed");
    }

    function cityRemove() {
        dispatch("city_removed");
    }

    function companyRemove() {
        dispatch("company_removed");
    }

    function companySizeRemove(alias) {
        dispatch("company_sizes_removed", {
            alias,
        });
    }

    function companyTypeRemove(alias) {
        dispatch("company_types_removed", {
            alias,
        });
    }

    function reviewsRemove() {
        dispatch("reviews_removed");
    }

    function topLargestRemove() {
        dispatch("in_top_largest_removed");
    }

    function newestRemove() {
        dispatch("newest_removed");
    }

    function remoteAvailableRemoved() {
        dispatch("remote_available_removed");
    }

    function photoExistsRemove() {
        dispatch("photo_exists_removed");
    }

    function getSalaryName(value) {
        switch (value) {
            case R_5000_TO_MORE:
                return `${localization.great} $5000`;
            case R_3000_TO_5000:
                return "$3000–5000";
            case R_2000_TO_3000:
                return "$2000–3000";
            case R_1000_TO_2000:
                return "$1000–2000";
            case R_500_TO_1000:
                return `${localization.less} $1000`;
        }

        return "";
    }

    function getCompanySizeName(value) {
        switch (value) {
            case CS_1500:
                return `${localization.great} 1500 ${localization.humans}`;
            case CS_800:
                return `${CS_800} ${localization.humans}`;
            case CS_200:
                return `${CS_200} ${localization.humans}`;
            case CS_81:
                return `${CS_81} ${localization.humans}`;
            case CS_1:
                return `${localization.less} 80 ${localization.humans}`;
        }

        return "";
    }

    $: exists = salaryFilterValue !== null ||
        selectedCityName !== "" ||
        selectedCompanyName !== "" ||
        companySizesFilterValue.length > 0 ||
        companyTypesFilterValue.length > 0 ||
        reviewsFilterValue ||
        topLargestFilterValue ||
        newestFilterValue ||
        remoteAvailableFilterValue ||
        photoExistsFilterValue;
</script>

{#if exists}
    <div class="search__pick pick">
        <div class="pick__pin"></div>
        <div class="pick__wr">
            {#if salaryFilterValue !== null}
                <div class="pick__it">
                    <div class="pick__a"><span>{getSalaryName(salaryFilterValue)}</span></div>
                    <div class="pick__x" on:click="{salaryRemove}">×</div>
                </div>
            {/if}
            {#if selectedCityName !== ""}
                <div class="pick__it">
                    <div class="pick__a"><span>{selectedCityName}</span></div>
                    <div class="pick__x" on:click="{cityRemove}">×</div>
                </div>
            {/if}
            {#if selectedCompanyName !== ""}
                <div class="pick__it">
                    <div class="pick__a"><span>{selectedCompanyName}</span></div>
                    <div class="pick__x" on:click="{companyRemove}">×</div>
                </div>
            {/if}
            {#each companySizesFilterValue as alias}
                <div class="pick__it">
                    <div class="pick__a"><span>{getCompanySizeName(alias)}</span></div>
                    <div class="pick__x" on:click="{() => companySizeRemove(alias)}">×</div>
                </div>
            {/each}
            {#each companyTypesFilterValue as alias}
                <div class="pick__it">
                    <div class="pick__a"><span>{getCompanyTypeNameByAlias(alias)}</span></div>
                    <div class="pick__x" on:click="{() => companyTypeRemove(alias)}">×</div>
                </div>
            {/each}
            {#if reviewsFilterValue}
                <div class="pick__it">
                    <div class="pick__a"><span>{localization.company_with_reviews}</span></div>
                    <div class="pick__x" on:click="{reviewsRemove}">×</div>
                </div>
            {/if}
            {#if topLargestFilterValue}
                <div class="pick__it">
                    <div class="pick__a"><span>{localization.company_in_top_largest}</span></div>
                    <div class="pick__x" on:click="{topLargestRemove}">×</div>
                </div>
            {/if}
            {#if newestFilterValue}
                <div class="pick__it">
                    <div class="pick__a"><span>{localization.last_week}</span></div>
                    <div class="pick__x" on:click="{newestRemove}">×</div>
                </div>
            {/if}
            {#if remoteAvailableFilterValue}
                <div class="pick__it">
                    <div class="pick__a"><span>{localization.remote_available}</span></div>
                    <div class="pick__x" on:click="{remoteAvailableRemoved}">×</div>
                </div>
            {/if}
            {#if photoExistsFilterValue}
                <div class="pick__it">
                    <div class="pick__a"><span>{localization.photo_exists}</span></div>
                    <div class="pick__x" on:click="{photoExistsRemove}">×</div>
                </div>
            {/if}
        </div>
    </div>
{/if}