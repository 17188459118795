import Range from "../range";
import {ModifierBuilder} from "./modifier";
import VacancyModifier from "./vacancy-modifier";
import {SalaryTitleRange} from "../entities";

export default class SalaryModifierBuilder implements ModifierBuilder {
    constructor(
        private readonly state: () => Range,
    ) {
    }

    build(): SalaryModifier {
        const range = this.state();

        if (range === null) {
            return null;
        }

        return new SalaryModifier(range);
    }
}

class SalaryModifier extends VacancyModifier {
    constructor(criteria: Range) {
        let from = criteria.from;
        let to = criteria.to;

        if (to === 0) {
            to = Number.MAX_SAFE_INTEGER;
        }

        super(function (vacancy) {
            if (vacancy.salary === null) {
                return false;
            }

            return salaryBetweenRange(vacancy.salary, from, to);
        });
    }
}

function salaryBetweenRange(salary: SalaryTitleRange, from, to) {
    return between(salary.from, salary.to, from) || between(salary.from, salary.to, to);
}

function between(from, to, value) {
    return from <= value && value <= to;
}