import ukrainian from "./ukrainian";
import english from "./english";
import russian from "./russian";
import armenian from "./armenian";

let localization = ukrainian;
let currentLanguage = "uk";

export function setLanguage(language: string) {
    switch (language) {
        case "en":
            localization = english;
            currentLanguage = "en";
            break;
        case "ru":
            localization = russian;
            currentLanguage = "ru";
            break;
        case "am":
            localization = armenian;
            currentLanguage = "am";
            break;
        default:
            localization = ukrainian;
            currentLanguage = "uk";
            break;
    }
}

export function getLanguage(): string {
    return currentLanguage;
}

export {
    localization,
};