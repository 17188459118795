import {Location} from "./location"

const map = {
    "kyiv": new Location(50.4428, 30.5023),
    "kharkiv": new Location(49.9962, 36.2356),
    "lviv": new Location(49.8319, 24.0221),
    "dnipro": new Location(48.4503, 35.0663),
    "odessa": new Location(46.4471, 30.7585),
    "vinnica": new Location(49.2382, 28.4516),
    "zaporizhia": new Location(47.8337, 35.1382),
    "chernivtsi": new Location(48.2931, 25.9742),
    "ivano-frankivsk": new Location(48.9269, 24.7177),
    "uzhhorod": new Location(48.6072, 22.2811),
    "khmelnytskyi": new Location(49.4291, 26.9971),
    "kherson": new Location(46.6369, 32.612),
    "mykolaiv": new Location(46.9659, 31.976),
    "cherkasy": new Location(49.43575, 32.0642),
    "zhitomir": new Location(50.2643, 28.6654),
    "chernihiv": new Location(51.5024, 31.2830),
    "ternopil": new Location(49.5526, 25.5877),
    "lutsk": new Location(50.75211, 25.3464),
    "sumy": new Location(50.9223, 34.8173),
    "rivne": new Location(50.6186, 26.2501),
    "mariupol": new Location(47.0850, 37.5403),
    "poltava": new Location(49.5878, 34.5543),
    "kropyvnytskyi": new Location(48.5027, 32.2525),
    "kamianets-podilskyi": new Location(48.677, 26.584),
    "mukachevo": new Location(48.4227, 22.7327),
    "brovary": new Location(50.5129, 30.83107),
    "boyarka": new Location(50.3318, 30.2962),
};

export function getLocationByCityAlias(alias: string): Location {
    if (map.hasOwnProperty(alias)) {
        return map[alias];
    }

    return null;
}
